import React, { useContext } from "react";
import SliderComponent from "../../components/home/slide";
import "./home.css";

import NuestrosAccesosComponent from "../../components/home/nuestros-accesos-grid";
import WhatsappComponent from "../../components/home/panel-whatsapp";
import NovedadesComponent from "../../components/home/novedades";
import EstudiaComponent from "../../components/home/estudia";
import CentrosMedicosComponent from "../../components/home/centros-medicos";
import BajarAppComponent from "../../components/home/bajar-app";
import LogosComponent from "../../components/home/logos";

import { GlobalContextMemorySpace } from "../../context/globalContext";

import CommonLoader from "../../components/common-loader";
import WhatsappFloatComponent from "components/comun/whatsapp";
import { PopUpComponent } from "components/pop-up";
import SocialNetworkComponent from "components/home/social-network";

function HomePage() {
  const { showLoaderHomePage } = useContext(GlobalContextMemorySpace);

  return (
    <>
      {showLoaderHomePage ? (
        <CommonLoader></CommonLoader>
      ) : (
        <>
          <PopUpComponent />
          <WhatsappFloatComponent />
          {/* Home Slide  */}

          <SliderComponent />

          {/* Section Panel Whatsapp */}
          <WhatsappComponent />
          <div className="bg-grandient-novedades bg-grandient-homee">
            <NovedadesComponent />

            <NuestrosAccesosComponent />

            <SocialNetworkComponent />

            <EstudiaComponent />

            <CentrosMedicosComponent />
          </div>
          {/* Section Bajate la app */}
          <BajarAppComponent />
          {/* Section Logos */}
          <LogosComponent />
        </>
      )}
    </>
  );
}

export default HomePage;
