/* eslint-disable eqeqeq */
import HBOnlineTransparent from "../../assets/img/hb-logo-small-transparent.png";
import HBOnlineColor from "../../assets/img/hb-logo-small-color.png";
import IconLinkedin from "../../assets/img/redes/LinkedIn.svg";
import IconInsta from "../../assets/img/redes/Instagram.svg";
import IconFb from "../../assets/img/redes/Facebook.svg";
import ArrowDown from "../../assets/img/arrow-down.png";
import IconTw from "../../assets/img/redes/Xblanco.svg";
import IconYt from "../../assets/img/redes/Youtube.svg";
import IconMail from "../../assets/img/redes/Mail.svg";
// import Logo from "../../assets/img/Isologotipo.png";
import Logo from "../../assets/img/Isologotipo180.svg";
import ArrowUp from "../../assets/img/arrow-up.png";
import LogoUBA from "../../assets/img/logo_UBA.png";
import LogoUCA from "../../assets/img/logo_UCA.png";
import LogoHB from "../../assets/img/logo_HB.png";

/* Iconos mobile*/
import IconLinkedinMbl from "../../assets/img/redes_mobile/LinkedIn.svg";
import IconInstaMbl from "../../assets/img/redes_mobile/Instagram.svg";
import IconFbMbl from "../../assets/img/redes_mobile/Facebook.svg";
import IconTwMbl from "../../assets/img/redes_mobile/Twitter.svg";
import IconYtMbl from "../../assets/img/redes_mobile/YouTube.svg";
import IconMailMbl from "../../assets/img/redes_mobile/Mail.svg";

import "./navbar.css";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import React from "react";

import { GlobalContextMemorySpace } from "../../context/globalContext";
import MenuDocencia from "./menus/docencia-e-investigacion";
import MenuInstitucional from "./menus/institucional";
import MenuPaciente from "./menus/paciente";
import { fetch } from "../../utils/fetch";
import MenuMedicos from "./menus/medicos";

function NavbarComponent() {
  const [lang, setLang] = React.useState("es");
  const [menus, setMenus] = React.useState([]);
  const [subMenus, setSubMenus] = React.useState({
    columna1: null,
    columna2: null,
  });

  const { NavBarSectionFetchData } = React.useContext(GlobalContextMemorySpace);
  const [showMenuPaciente, setShowMenuPaciente] = React.useState(false);
  const [showMenuDocencia, setShowMenuDocencia] = React.useState(false);

  const [showMenuMedicos, setShowMenuMedicos] = React.useState(false);
  const [showNavMobile, setShowNavMobile] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  const [phoneInfo, setPhoneInfo] = React.useState();
  const [renderInfo, setRenderInfo] = React.useState();

  React.useEffect(() => {});

  React.useEffect(() => {
    (() => {
      const listadoMenu =
        NavBarSectionFetchData ||
        [].map((item) => {
          return { ...item, visible: false };
        });

      setMenus(listadoMenu);

      window.addEventListener("scroll", handleScroll);
    })();

    fetch("seccion-home-whatsapps").then((res) => {
      const { data } = res;
      const [info] = data;
      if (info) {
        const newInfo = {
          contenido: info.contenido.replaceAll("-", ""),
          callCenter: info.callCenter.replaceAll("-", ""),
          whatsapp: info.whatsapp.replaceAll("-", ""),
        };
        setRenderInfo(newInfo);
        setPhoneInfo(info);
      }
    });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lang, NavBarSectionFetchData]);

  const onToggleMenu = (id) => {
    const listadoMenu = menus.map((item) => {
      if (item.id === id) {
        return { ...item, visible: !item.visible };
      } else return item;
    });

    setMenus(listadoMenu);
  };

  const handleShowMenu = (menu, submenucol1, submenucol2) => {
    switch (menu) {
      case "pacientes":
        setSubMenus({
          ...subMenus,
          columna1: submenucol1,
          columna2: submenucol2,
        });
        setShowMenuPaciente(true);
        setShowMenuMedicos(false);
        setShowMenuDocencia(false);
        setShowMenu(false);
        break;

      case "medicos":
        setSubMenus({
          ...subMenus,
          columna1: submenucol1,
          columna2: submenucol2,
        });
        setShowMenuPaciente(false);
        setShowMenuMedicos(true);
        setShowMenuDocencia(false);
        setShowMenu(false);
        break;

      case "docencia":
        setSubMenus({
          ...subMenus,
          columna1: submenucol1,
          columna2: submenucol2,
        });
        setShowMenuPaciente(false);
        setShowMenuMedicos(false);
        setShowMenuDocencia(true);
        setShowMenu(false);
        break;

      case "institucional":
        setSubMenus({
          ...subMenus,
          columna1: submenucol1,
          columna2: submenucol2,
        });
        setShowMenuPaciente(false);
        setShowMenuMedicos(false);
        setShowMenuDocencia(false);
        setShowMenu(true);
        break;
    }
  };

  const handleScroll = (event) => {
    setShowMenuPaciente(false);
    setShowMenuMedicos(false);
    setShowMenuDocencia(false);
    setShowMenu(false);
  };

  const accionTelefono = () => {
    ReactGA.event({
      category: `Contacto`,
      action: "Clic",
      label: "Clic en Teléfonos Header",
    });
  };

  const accionBotonHB = () => {
    ReactGA.event({
      category: `HB-Online`,
      action: "Clic",
      label: "Clic en Botón Menu HBO",
    });
  };

  const accionBotonPlan = () => {
    ReactGA.event({
      category: `Plan de Salud`,
      action: "Clic",
      label: "Clic en Botón Menu PS",
    });
  };

  return (
    <div>
      <div className="grandientNavbar">
        <div className="grandientNavbar_element1">
          <span className="phoneText d-block d-md-none">
            Atención telefónica las 24hs:{" "}
            <a
              href={`tel:${renderInfo?.callCenter}`}
              style={{ color: "#FFFFFF", textDecoration: "none" }}
              onClick={accionTelefono}
            >
              {phoneInfo?.callCenter}
            </a>
          </span>
          <span className="phoneText d-none d-md-block">
            Atención telefónica las 24hs:{" "}
            <a
              href={`tel:${renderInfo?.callCenter}`}
              style={{ color: "#FFFFFF", textDecoration: "none" }}
              onClick={accionTelefono}
            >
              {phoneInfo?.callCenter}
            </a>{" "}
            / Central de turnos:{" "}
            <a
              href={`tel:${renderInfo?.contenido}`}
              style={{ color: "#FFFFFF", textDecoration: "none" }}
            >
              {phoneInfo?.contenido}
            </a>{" "}
            /{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://wa.me/54${renderInfo?.whatsapp}`}
              style={{ color: "white" }}
            >
              <i class="bi bi-whatsapp" style={{ fontSize: 15 }} />{" "}
              {phoneInfo?.whatsapp}
            </a>
          </span>
        </div>
        <div className="grandientNavbar_elements d-none d-lg-block">
          <div className="icons d-flex">
            <a
              href="https://www.facebook.com/hospitalbritanico/"
              target="_blank"
            >
              <img src={IconFb} className="footer-icon px-1" alt="icon-fb" />
            </a>
            <a href="https://twitter.com/htal_britanico" target="_blank">
              <img
                src={IconTw}
                className="footer-icon px-1"
                alt="icon-fb-lg"
                style={{ maxHeight: "18px" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/hospital-brit-nico-buenos-aires"
              target="_blank"
            >
              <img
                src={IconLinkedin}
                className="footer-icon px-1"
                alt="icon-fb"
              />
            </a>
            <a
              href="https://www.instagram.com/hospitalbritanicoarg/"
              target="_blank"
            >
              <img src={IconInsta} className="footer-icon px-1" alt="icon-fb" />
            </a>
            <a href="https://www.youtube.com/hospitalbritanico" target="_blank">
              <img src={IconYt} className="footer-icon px-1" alt="icon-fb" />
            </a>
            <Link to={"/contacto"}>
              <img
                src={IconMail}
                className="img-fluid px-1 footer-icon"
                alt="icon-fb"
                style={{}}
              />
            </Link>
          </div>
        </div>
      </div>

      {/* MENU PC */}
      <div className="d-none d-lg-block">
        <Navbar className="customNavbar" expand="lg">
          <Navbar.Brand className="customNavbar_brand">
            <Link to="/">
              <img
                src={Logo}
                alt="logo"
                className="customNavbar_brand_logo"
                // style={{ width: "4em" }} //eliminar al volver al logo normal
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto nav-mega justify-content-end align-items-center">
              {menus.map((data, index) => {
                return (
                  <Nav.Link
                    key={index}
                    className="navItem__button"
                    onMouseEnter={() =>
                      handleShowMenu(
                        data.Menu.Menu.onClick,
                        data.Menu.SubmenuColumna1,
                        data.Menu.SubmenuColumna2
                      )
                    }
                  >
                    {data.Menu.Menu.nombre}
                  </Nav.Link>
                );
              })}

              <Nav.Link
                href="https://www.hbritanicoweb.com.ar"
                target="_blank"
                onClick={accionBotonHB}
              >
                <button type="button" className="btn navItem__button--hb">
                  <span className="color">
                    <img src={HBOnlineColor} alt="" />
                  </span>
                  <span className="transparent">
                    <img src={HBOnlineTransparent} alt="" />
                  </span>
                  Online
                </button>
              </Nav.Link>
              <Nav.Link
                href={process.env.REACT_APP_HOME_PS}
                target="_blank"
                onClick={accionBotonPlan}
              >
                <button
                  type="button"
                  className="btn navItem__button--plandesalud"
                >
                  Plan de salud
                </button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      {/* MENU MOVIL */}
      <div className="d-block d-lg-none">
        <Navbar expanded={showNavMobile} className="customNavbar" expand="lg">
          <Navbar.Brand href="/" className="ms-2 customNavbar_brand">
            <Link to="/">
              <img src={Logo} alt="logo" className="customNavbar_brand_logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setShowNavMobile(!showNavMobile)}
            className="me-2"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse-mv">
            <Nav className="justify-content-center align-items-start ms-2">
              {menus.map((data, index) => {
                return (
                  <NavDropdown
                    key={index}
                    title={
                      <div
                        className="d-flex justify-content-between pb-2 me-1 dropdown-menu-mv"
                        onClick={() => onToggleMenu(data.id)}
                      >
                        <div className="item-menu-mv">
                          {data.Menu.Menu.nombre}
                        </div>
                        <div className="me-2">
                          {data.visible ? (
                            <img src={ArrowUp} alt="..." />
                          ) : (
                            <img src={ArrowDown} alt="..." />
                          )}
                        </div>
                      </div>
                    }
                    className="w-100"
                    id="basic-nav-dropdown"
                  >
                    {/* Docencia e investigación */}
                    {data.id === 3 && (
                      <>
                        <span className="convenios">Convenios</span>
                        <div className="text-center text-md-start">
                          <div className="row g-0 mb-3 mt-2">
                            <div className="col">
                              <img
                                src={LogoUBA}
                                className="me-1"
                                style={{ width: 130 }}
                                alt="..."
                              />
                              &
                              <img
                                src={LogoHB}
                                className="ms-1"
                                style={{ width: 130 }}
                                alt="..."
                              />
                            </div>
                          </div>
                          <div className="row g-0 mb-2">
                            <div className="col">
                              <img
                                src={LogoUCA}
                                className="me-1"
                                style={{ width: 130 }}
                                alt="..."
                              />
                              &
                              <img
                                src={LogoHB}
                                className="ms-1"
                                style={{ width: 130 }}
                                alt="..."
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {data.Menu.SubmenuColumna1?.concat(
                      data.Menu.SubmenuColumna2
                    ).map((sub, idx) => {
                      if (
                        sub.url.toLowerCase().startsWith("http:") ||
                        sub.url.toLowerCase().startsWith("https:")
                      ) {
                        return (
                          <NavDropdown.Item
                            key={idx}
                            target="_blank"
                            href={sub.url}
                            onClick={() => setShowNavMobile(false)}
                          >
                            <span className="subitem-menu-mv">
                              {sub.nombre}
                            </span>
                          </NavDropdown.Item>
                        );
                      } else {
                        return (
                          <NavDropdown.Item
                            key={idx}
                            onClick={() => setShowNavMobile(false)}
                          >
                            <Link key={idx} to={sub.url}>
                              <span className="subitem-menu-mv">
                                {sub.nombre}
                              </span>
                            </Link>
                          </NavDropdown.Item>
                        );
                      }
                    })}
                  </NavDropdown>
                );
              })}
              <div
                className="w-100 d-flex flex-row justify-content-around"
                style={{ borderBottom: "1px solid #abc3da" }}
              >
                <Nav.Link
                  href="https://www.hbritanicoweb.com.ar"
                  target="_blank"
                >
                  <button type="button" className="btn navItem__button--hb">
                    <span className="color">
                      <img src={HBOnlineColor} alt="" />
                    </span>
                    <span className="transparent">
                      <img src={HBOnlineTransparent} alt="" />
                    </span>
                    Online
                  </button>
                </Nav.Link>
                <Nav.Link href={process.env.REACT_APP_HOME_PS} target="_blank">
                  <button
                    type="button"
                    className="btn navItem__button--plandesalud"
                  >
                    Plan de salud
                  </button>
                </Nav.Link>
              </div>

              <div className="w-100 d-flex flex-row justify-content-around pb-2 pt-2">
                <a
                  href="https://www.facebook.com/hospitalbritanico/"
                  target="_blank"
                >
                  <img
                    src={IconFbMbl}
                    className="socialMediaMobile"
                    alt="icon-fb"
                  />
                </a>
                <a href="https://twitter.com/htal_britanico" target="_blank">
                  <img
                    src={IconTwMbl}
                    className="socialMediaMobile"
                    alt="icon-fb"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/hospital-brit-nico-buenos-aires"
                  target="_blank"
                >
                  <img
                    src={IconLinkedinMbl}
                    className="socialMediaMobile"
                    alt="icon-fb"
                  />
                </a>
                <a
                  href="https://www.instagram.com/hospitalbritanicoarg/"
                  target="_blank"
                >
                  <img
                    src={IconInstaMbl}
                    className="socialMediaMobile"
                    alt="icon-fb"
                  />
                </a>
                <a
                  href="https://www.youtube.com/hospitalbritanico"
                  target="_blank"
                >
                  <img
                    src={IconYtMbl}
                    className="socialMediaMobile"
                    alt="icon-fb"
                  />
                </a>
                <Link to={"/contacto"}>
                  <img
                    src={IconMailMbl}
                    className="socialMediaMobile"
                    alt="icon-fb"
                  />
                </Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <div>
        {/* Custom menu items */}
        {subMenus && (
          <MenuPaciente
            display={showMenuPaciente}
            subMenus={subMenus}
            onHide={() => setShowMenuPaciente(false)}
          />
        )}
        <MenuMedicos
          display={showMenuMedicos}
          subMenus={subMenus}
          onHide={() => setShowMenuMedicos(false)}
        />
        <MenuDocencia
          display={showMenuDocencia}
          subMenus={subMenus}
          onHide={() => setShowMenuDocencia(false)}
        />
        <MenuInstitucional
          display={showMenu}
          subMenus={subMenus}
          onHide={() => setShowMenu(false)}
        />
      </div>
    </div>
  );
}

export default NavbarComponent;
