import React, { useRef } from "react";
import Arrow from "../../../assets/img/arrow_down.png";
// import NovedadesArrow from "../../../assets/img/novedadesArrow.png";
import NovedadesArrow from "../../../assets/img/arrow_blue.png";
import "./novedades.css";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../../context/globalContext";
import { width } from "@mui/system";

const NovedadesComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const { NovedadesSectionFetchData } = React.useContext(
    GlobalContextMemorySpace
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    setContenido(NovedadesSectionFetchData);
  }, [NovedadesSectionFetchData]);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const changeSearch = async (value) => {
    navigate(`/especialidades/${value.slug}`, { replace: true });
  };

  function TarjetaImagen({ data }) {
    return (
      <div
        className={"card  border-0 w-100 shadow-lg"}
        style={{ height: "288px", borderRadius: "14px" }}
      >
        <div style={{ height: "153px", padding: "15px 15px 0px 15px" }}>
          <div
            className="d-none d-md-block"
            style={{
              backgroundImage: `url('${data?.imagen?.url}')`,
              height: "148px",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
            }}
          ></div>

          <div
            className="d-block d-md-none"
            style={{
              backgroundImage: `url('${data?.imagen?.url}')`,
              height: "164px",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
            }}
          ></div>
        </div>

        <div className="card-body" style={{ padding: "0px 14px 0px 14px" }}>
          <div className="d-none d-lg-block">
            <div
              className={"card-text texto-novedad-nodestacada  mt-2"}
              style={{ height: "65px", fontWeight: "bold" }}
            >
              {data.titulo}
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div
              className="card-text novelty-text-movil"
              style={{
                paddingTop: "33px",
                WebkitLineClamp: "2",
                fontWeight: "bold",
              }}
            >
              {data.titulo}
            </div>
          </div>
        </div>
        <div
          className="card-footer bg-transparent border-0"
          style={{ height: "46px" }}
        >
          <div className="d-flex justify-content-end">
            <div className="d-none d-lg-block">
              <Link to={"/novedades/" + data.slug}>
                <img
                  className="img-fluid"
                  style={{ width: "30px" }}
                  src={NovedadesArrow}
                  alt=""
                />
              </Link>
            </div>
            <div className="d-block d-lg-none">
              <Link to={"/novedades/" + data.slug}>
                <img
                  className="img-fluid"
                  style={{ width: "20px" }}
                  src={NovedadesArrow}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TarjetaImagenDestacada({ data }) {
    return (
      <div
        className={"card  border-0 w-100 shadow-lg tabletScreen"}
        style={{ height: "364px", borderRadius: "14px" }}
      >
        <div
          style={{
            aspectRatio: "600/270",
            padding: "15px 15px 0px 15px",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundImage: `url('${data?.imagen?.url}')`,
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
            }}
          ></div>
        </div>

        <div
          className="card-body"
          style={{ height: "81px", padding: "0px 14px 14px 14px" }}
        >
          <div className="d-none d-lg-block">
            <div
              className={"card-text texto-novedad-destacada  mt-2"}
              style={{
                lineHeight: "26px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {data.titulo}
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div className="card-text novelty-text-movil">{data.titulo}</div>
          </div>
        </div>
        <div
          className="card-footer bg-transparent border-0"
          style={{ height: "46px" }}
        >
          <div className="d-flex justify-content-end">
            <div className="d-none d-lg-block">
              <img
                className="img-fluid"
                style={{ width: "30px" }}
                src={NovedadesArrow}
                alt=""
              />
            </div>
            <div className="d-block d-lg-none">
              <img
                className="img-fluid"
                style={{ width: "20px" }}
                src={NovedadesArrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TarjetaSinImagen({ data }) {
    return (
      <div
        className={"card border-0 w-100 shadow-lg"}
        style={{ height: "288px", borderRadius: "14px" }}
      >
        <div style={{ height: "144px", minHeight: "144px", padding: "15px" }}>
          <span className={"novelty-title-nodestacado"}>{data.titulo}</span>
        </div>

        <div className="novelty-divisor"></div>

        <div className="card-body" style={{ height: "81px" }}>
          <div className="d-none d-lg-block">
            <div className="card-text texto-novedad-nodestacada">
              {data.descripcionCorta}
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div className="card-text  texto-novedad-movil">
              {data.descripcionCorta}
            </div>
          </div>
        </div>
        <div
          className="card-footer bg-transparent border-0"
          style={{ height: "46px" }}
        >
          <div className="d-flex justify-content-end">
            <div className="d-none d-lg-block">
              <img
                className="img-fluid"
                style={{ width: "30px" }}
                src={NovedadesArrow}
                alt=""
              />
            </div>
            <div className="d-block d-lg-none">
              <img
                className="img-fluid"
                style={{ width: "20px" }}
                src={NovedadesArrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TarjetaSinImagenDestacada({ data }) {
    return (
      <div
        className={"card border-0 w-100 shadow-lg"}
        style={{ height: "364px", borderRadius: "14px" }}
      >
        <div style={{ height: "144px", minHeight: "220px", padding: "15px" }}>
          <span className={"novelty-title-destacado"}>{data.titulo}</span>
        </div>

        <div className="novelty-divisor"></div>

        <div className="card-body" style={{ padding: "0px 14px 0px 14px" }}>
          <div className="d-none d-lg-block">
            <div className="card-text texto-novedad-destacada-sinimagen">
              {data.descripcionCorta}
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div className="card-text  texto-novedad-movil">
              {data.descripcionCorta}
            </div>
          </div>
        </div>
        <div
          className="card-footer bg-transparent border-0"
          style={{ height: "46px" }}
        >
          <div className="d-flex justify-content-end">
            <div className="d-none d-lg-block">
              <img
                className="img-fluid"
                style={{ width: "30px" }}
                src={NovedadesArrow}
                alt=""
              />
            </div>
            <div className="d-block d-lg-none">
              <img
                className="img-fluid"
                style={{ width: "20px" }}
                src={NovedadesArrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TarjetaNovedad({ data }) {
    if (data.imagen == null) {
      if (!data.destacado) return <TarjetaSinImagen data={data} />;
      else return <TarjetaSinImagenDestacada data={data} />;
    } else {
      if (!data.destacado) return <TarjetaImagen data={data} />;
      else return <TarjetaImagenDestacada data={data} />;
    }
  }

  return (
    <section className="section-novedades" ref={myRef}>
      <div className="container container-novedades-home">
        <div className="row">
          <div className="d-none d-lg-block">
            <div className="col section-novedades_titulo mb-4">
              <h2 className="text-white pb-4">Novedades</h2>
              <Link
                to="/novedades"
                className="section-novedades_button_rounded"
              >
                Ver todas
              </Link>
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div className="col section-novedades-movil_titulo">
              <h2 className="text-white mt-4">Novedades</h2>
              <Link
                to="/novedades"
                className="section-novedades-movil_button_rounded"
              >
                Ver todas
              </Link>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-baseline mb-5">
          {contenido &&
            contenido.slice(0, 3).map((data, index) => {
              return (
                <>
                  <div
                    className={`${
                      data.destacado
                        ? "d-none d-lg-inline-block col-5 card-destacado"
                        : "d-none d-lg-inline-block col card-no-destacado"
                    }`}
                  >
                    <div>
                      <Link
                        to={"/novedades/" + data.slug}
                        className="d-flex justify-content-end"
                      >
                        <TarjetaNovedad data={data} />
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`${
                      data.destacado
                        ? "d-block d-lg-none col-12 px-3 card-destacado"
                        : "d-block d-lg-none col-12 px-3 card-no-destacado"
                    }`}
                  >
                    <div>
                      <Link
                        to={"/novedades/" + data.slug}
                        className="d-flex justify-content-center mb-3"
                      >
                        <TarjetaNovedad data={data} />
                      </Link>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn"
              onClick={() => executeScroll()}
            >
              <img src={Arrow} style={{ width: "30pt" }} alt="arrow" />
            </button>
          </div>
        </div>
{/* 
        <div className="row pt-5">
          <div className="col-lg-12">
            <div className="d-none d-lg-block">
              <h2 className="nuestros-accesos">Información Importante</h2>
            </div>
            <div className="d-block d-lg-none">
              <h2 className="nuestros-accesos-movil">Información Importante</h2>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default NovedadesComponent;
