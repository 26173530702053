import React, { useState, useRef } from "react";
import "./nuestros-accesos.css";

import Texto1 from "../../../assets/img/texto-fondo-1.png";
import Texto2 from "../../../assets/img/texto-fondo-2.png";
import Texto3 from "../../../assets/img/texto-fondo-3.png";
import Texto4 from "../../../assets/img/texto-fondo-4.png";
import Texto5 from "../../../assets/img/texto-fondo-5.png";
import Texto6 from "../../../assets/img/texto-fondo-6.png";
import AccesoFondo1 from "../../../assets/img/acceso-fondo-1.jpg";
import AccesoFondo2 from "../../../assets/img/acceso-fondo-2.jpg";
import AccesoFondo3 from "../../../assets/img/acceso-fondo-3.jpg";
import AccesoFondo4 from "../../../assets/img/acceso-fondo-4.jpg";
import AccesoFondo5 from "../../../assets/img/acceso-fondo-5.jpg";
import AccesoFondo6 from "../../../assets/img/acceso-fondo-6.jpg";

// import Acceso1 from '../../../assets/img/acceso-1.png'
// import Acceso2 from '../../../assets/img/acceso-2.png'
// import Acceso3 from '../../../assets/img/acceso-3.png'
// import Acceso4 from '../../../assets/img/acceso-4.png'
// import Acceso5 from '../../../assets/img/acceso-5.png'
// import Acceso6 from '../../../assets/img/acceso-6.png'

import { fetch } from "../../../utils/fetch";

import Arrow from "../../../assets/img/arrow_down.png";
import { Link } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../../context/globalContext";
// const Texto1 = require('../../../assets/img/texto-fondo-1.png');

const NuestrosAccesosComponent = () => {
  const [accesos, setAccesos] = React.useState(null);
  const { NuestrosAccesosSectionFetchData } = React.useContext(
    GlobalContextMemorySpace
  );

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  React.useEffect(() => {
    getAccesos();
    window.scrollTo({ top: 0 });
  }, [NuestrosAccesosSectionFetchData]);

  const getAccesos = async () => {
    //const { data } = await fetch("nuestros-accesos?_sort=orden:ASC", 'GET');
    const data = NuestrosAccesosSectionFetchData;
    //console.log("NUESTROS ACCESOS",data);

    setAccesos(data);

    setbackImageSm(
      data && [
        `url(${data[0]?.imagenAcceso.url})`,
        `url(${data[1]?.imagenAcceso.url})`,
        `url(${data[2]?.imagenAcceso.url})`,
        `url(${data[3]?.imagenAcceso.url})`,
        `url(${data[4]?.imagenAcceso.url})`,
        `url(${data[5]?.imagenAcceso.url})`,
      ]
    );

    setImagenesAcceso(
      data && [
        `url(${data[0]?.imagenAcceso.url})`,
        `url(${data[1]?.imagenAcceso.url})`,
        `url(${data[2]?.imagenAcceso.url})`,
        `url(${data[3]?.imagenAcceso.url})`,
        `url(${data[4]?.imagenAcceso.url})`,
        `url(${data[5]?.imagenAcceso.url})`,
      ]
    );
  };

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const [backImage, setBackImage] = useState(
    `${process.env.PUBLIC_URL}/assets/images/acceso-fondo-1.png`
  );
  //const [opacity, setOpacity] = useState('1.0');
  const [opacity, setOpacity] = useState([
    "100% 100%",
    "100% 100%",
    "100% 100%",
    "100% 100%",
    "100% 100%",
    "100% 100%",
  ]);
  const [backImageSm, setbackImageSm] = useState(["", "", "", "", "", ""]);
  const [imagenesAcceso, setImagenesAcceso] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const novedadOver = (e) => {
    let select = e.currentTarget.id;
    switch (select) {
      case "1":
        setOpacity(["100% 100%", "0", "0", "0", "0", "0"]);
        //sham
        setbackImageSm([
          `url(${accesos && accesos[0]?.textoFondo.url})`,
          "",
          "",
          "",
          "",
          "",
        ]);
        //fondo
        setBackImage(accesos && accesos[0]?.imagenFondo.url);
        break;
      case "2":
        setOpacity(["0", "100% 100%", "0", "0", "0", "0"]);
        setbackImageSm([
          "",
          `url(${accesos && accesos[1]?.textoFondo.url})`,
          "",
          "",
          "",
          "",
        ]);
        setBackImage(accesos && accesos[1]?.imagenFondo.url);
        break;
      case "3":
        setOpacity(["0", "0", "100% 100%", "0", "0", "0"]);
        setbackImageSm([
          "",
          "",
          `url(${accesos && accesos[2]?.textoFondo.url})`,
          "",
          "",
          "",
        ]);
        setBackImage(accesos && accesos[2]?.imagenFondo.url);
        break;
      case "4":
        setOpacity(["0", "0", "0", "100% 100%", "0", "0"]);
        setbackImageSm([
          "",
          "",
          "",
          `url(${accesos && accesos[3]?.textoFondo.url})`,
          "",
          "",
        ]);
        setBackImage(accesos && accesos[3]?.imagenFondo.url);
        break;
      case "5":
        setOpacity(["0", "0", "0", "0", "100% 100%", "0"]);
        setbackImageSm([
          "",
          "",
          "",
          "",
          `url(${accesos && accesos[4]?.textoFondo.url})`,
          "",
        ]);
        setBackImage(accesos && accesos[4]?.imagenFondo.url);
        break;
      case "6":
        setOpacity(["0", "0", "0", "0", "0", "100% 100%"]);
        setbackImageSm([
          "",
          "",
          "",
          "",
          "",
          `url(${accesos && accesos[5]?.textoFondo.url})`,
        ]);
        setBackImage(accesos && accesos[5]?.imagenFondo.url);
        break;
      default:
    }
  };

  const novedadOut = () => {
    setbackImageSm(imagenesAcceso);

    setOpacity([
      "100% 100%",
      "100% 100%",
      "100% 100%",
      "100% 100%",
      "100% 100%",
      "100% 100%",
    ]);
  };

  return accesos ? (        
    <section className="section-nuestros-accesos" ref={myRef}>
      <div className="container">
      <div className="row pb-5">
      <div className="col-lg-12">
        <div className="d-none d-lg-block">
          <h2 className="nuestros-accesos">Información Importante</h2>
        </div>
        <div className="d-block d-lg-none">
          <h2 className="nuestros-accesos-movil">Información Importante</h2>
        </div>
      </div>
    </div>
    
        <div className="row pb-5">
          <div className="col">
            <div style={{ textAlign: "center" }}>
              <div style={{ display: "none" }}>
                {/* sham, no sirve */}
                <img src={AccesoFondo1} alt="fondo" />
                <img src={AccesoFondo2} alt="fondo" />
                <img src={AccesoFondo3} alt="fondo" />
                <img src={AccesoFondo4} alt="fondo" />
                <img src={AccesoFondo5} alt="fondo" />
                <img src={AccesoFondo6} alt="fondo" />
                <img src={Texto1} alt="fondo" />
                <img src={Texto2} alt="fondo" />
                <img src={Texto3} alt="fondo" />
                <img src={Texto4} alt="fondo" />
                <img src={Texto5} alt="fondo" />
                <img src={Texto6} alt="fondo" />
              </div>

              <div className="d-none d-lg-block">
                <div className={"content contMD"}>
                  <div
                    id="axis"
                    className={"panelaccesos title_grid panelaccesos_probando"}
                    style={{ backgroundImage: `url(${backImage}` }}
                  >
                    <div className="panelaccesos fila">
                      {/* {accesos &&
                        accesos[0]?.enlaceInterno ?
                        <Link to={accesos[0]?.enlace}>
                          <Acceso id="1" indice={0} texto="MEDICOS" />
                        </Link> :
                        <a href={accesos[0]?.enlace} target="_blank">
                          <Acceso id="1" indice={0} texto="MEDICOS" />
                        </a>
                      } */}

                      {accesos[0]?.enlaceInterno ? (
                        <Link
                          to={
                            !accesos[0]?.tieneEnlace ? "#" : accesos[0]?.enlace
                          }
                        >
                          <div
                            id="1"
                            className="acceso1 title_grid acceso"
                            style={{
                              //sham
                              backgroundImage: backImageSm[0], //`url(${accesos && accesos[0]?.imagenAcceso.url})`,
                              backgroundSize: opacity[0],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            MEDICOS
                          </div>
                        </Link>
                      ) : (
                        <a
                          href={
                            !accesos[0]?.tieneEnlace ? "#" : accesos[0]?.enlace
                          }
                          target={
                            accesos[0]?.tieneEnlace ? "_blank" : "_parent"
                          }
                        >
                          <div
                            id="1"
                            className="acceso1 title_grid acceso"
                            style={{
                              //sham
                              backgroundImage: backImageSm[0], //`url(${accesos && accesos[0]?.imagenAcceso.url})`,
                              backgroundSize: opacity[0],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            MEDICOS
                          </div>
                        </a>
                      )}

                      {accesos[1]?.enlaceInterno ? (
                        <Link
                          to={
                            !accesos[1]?.tieneEnlace ? "#" : accesos[1]?.enlace
                          }
                        >
                          <div
                            id="2"
                            className="acceso2 title_grid acceso"
                            style={{
                              backgroundImage: backImageSm[1],
                              backgroundSize: opacity[1],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            PACIENTES
                          </div>
                        </Link>
                      ) : (
                        <a
                          href={
                            !accesos[1]?.tieneEnlace ? "#" : accesos[1]?.enlace
                          }
                          target={
                            accesos[1]?.tieneEnlace ? "_blank" : "_parent"
                          }
                        >
                          <div
                            id="2"
                            className="acceso2 title_grid acceso"
                            style={{
                              backgroundImage: backImageSm[1],
                              backgroundSize: opacity[1],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            PACIENTES
                          </div>
                        </a>
                      )}

                      {accesos[2]?.enlaceInterno ? (
                        <Link
                          to={
                            !accesos[2]?.tieneEnlace ? "#" : accesos[2]?.enlace
                          }
                        >
                          <div
                            id="3"
                            className="acceso3 title_grid acceso"
                            style={{
                              backgroundImage: backImageSm[2],
                              backgroundSize: opacity[2],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            COBERTURA MEDICA
                          </div>
                        </Link>
                      ) : (
                        <a
                          href={
                            !accesos[2]?.tieneEnlace ? "#" : accesos[2]?.enlace
                          }
                          target={
                            accesos[2]?.tieneEnlace ? "_blank" : "_parent"
                          }
                        >
                          <div
                            id="3"
                            className="acceso3 title_grid acceso"
                            style={{
                              backgroundImage: backImageSm[2],
                              backgroundSize: opacity[2],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            COBERTURA MEDICA
                          </div>
                        </a>
                      )}
                    </div>
                    <div className="panelaccesos fila">
                      {accesos[3]?.enlaceInterno ? (
                        <Link
                          to={
                            !accesos[3]?.tieneEnlace ? "#" : accesos[3]?.enlace
                          }
                        >
                          <div
                            id="4"
                            className="acceso4 title_grid acceso"
                            style={{
                              backgroundImage: backImageSm[3],
                              backgroundSize: opacity[3],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            DONACIONES
                          </div>
                        </Link>
                      ) : (
                        <a
                          href={
                            !accesos[3]?.tieneEnlace ? "#" : accesos[3]?.enlace
                          }
                          target={
                            accesos[3]?.tieneEnlace ? "_blank" : "_parent"
                          }
                        >
                          <div
                            id="4"
                            className="acceso4 title_grid acceso"
                            style={{
                              backgroundImage: backImageSm[3],
                              backgroundSize: opacity[3],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            DONACIONES
                          </div>
                        </a>
                      )}

                      {accesos[4]?.enlaceInterno ? (
                        <Link
                          to={
                            !accesos[4]?.tieneEnlace ? "#" : accesos[4]?.enlace
                          }
                        >
                          <div
                            id="5"
                            className={"acceso5 title_grid acceso"}
                            style={{
                              backgroundImage: backImageSm[4],
                              backgroundSize: opacity[4],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            DIAGNOSTICO POR IMAGENES
                          </div>
                        </Link>
                      ) : (
                        <a
                          href={
                            !accesos[4]?.tieneEnlace ? "#" : accesos[4]?.enlace
                          }
                          target={
                            accesos[4]?.tieneEnlace ? "_blank" : "_parent"
                          }
                        >
                          <div
                            id="5"
                            className={"acceso5 title_grid acceso"}
                            style={{
                              backgroundImage: backImageSm[4],
                              backgroundSize: opacity[4],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            DIAGNOSTICO POR IMAGENES
                          </div>
                        </a>
                      )}

                      {accesos[5]?.enlaceInterno ? (
                        <Link
                          to={
                            !accesos[5]?.tieneEnlace ? "#" : accesos[5]?.enlace
                          }
                        >
                          <div
                            id="6"
                            className={"acceso6 title_grid acceso"}
                            style={{
                              backgroundImage: backImageSm[5],
                              backgroundSize: opacity[5],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            LABORATORIO
                          </div>
                        </Link>
                      ) : (
                        <a
                          href={
                            !accesos[5]?.tieneEnlace ? "#" : accesos[5]?.enlace
                          }
                          target={
                            accesos[5]?.tieneEnlace ? "_blank" : "_parent"
                          }
                        >
                          <div
                            id="6"
                            className={"acceso6 title_grid acceso"}
                            style={{
                              backgroundImage: backImageSm[5],
                              backgroundSize: opacity[5],
                            }}
                            onMouseOver={novedadOver}
                            onMouseOut={novedadOut}
                          >
                            LABORATORIO
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-lg-none g-0">
                <div className={"row m-1"}>
                  {/* IMG1 */}
                  <div className="col-12 col-md-6">
                    {accesos && accesos[0]?.enlaceInterno ? (
                      <Link
                        to={!accesos[0]?.tieneEnlace ? "#" : accesos[0]?.enlace}
                      >
                        <img
                          src={accesos && accesos[0]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </Link>
                    ) : (
                      <a
                        href={
                          !accesos[0]?.tieneEnlace ? "#" : accesos[0]?.enlace
                        }
                        target={accesos[0]?.tieneEnlace ? "_blank" : "_parent"}
                      >
                        <img
                          src={accesos && accesos[0]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </a>
                    )}
                  </div>

                  {/* IMG2 */}
                  <div className="col-12 col-md-6">
                    {accesos && accesos[1]?.enlaceInterno ? (
                      <Link
                        to={!accesos[1]?.tieneEnlace ? "#" : accesos[1]?.enlace}
                      >
                        <img
                          src={accesos && accesos[1]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </Link>
                    ) : (
                      <a
                        href={
                          !accesos[1]?.tieneEnlace ? "#" : accesos[1]?.enlace
                        }
                        target={accesos[1]?.tieneEnlace ? "_blank" : "_parent"}
                      >
                        <img
                          src={accesos && accesos[1]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </a>
                    )}
                  </div>
                </div>

                <div className={"row m-1"}>
                  {/* IMG3 */}
                  <div className="col-12 col-md-6">
                    {accesos && accesos[2]?.enlaceInterno ? (
                      <Link
                        to={!accesos[2]?.tieneEnlace ? "#" : accesos[2]?.enlace}
                      >
                        <img
                          src={accesos && accesos[2]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </Link>
                    ) : (
                      <a
                        href={
                          !accesos[2]?.tieneEnlace ? "#" : accesos[2]?.enlace
                        }
                        target={accesos[2]?.tieneEnlace ? "_blank" : "_parent"}
                      >
                        <img
                          src={accesos && accesos[2]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </a>
                    )}
                  </div>

                  {/* IMG4 */}
                  <div className="col-12 col-md-6">
                    {accesos && accesos[3]?.enlaceInterno ? (
                      <Link
                        to={!accesos[3]?.tieneEnlace ? "#" : accesos[3]?.enlace}
                      >
                        <img
                          src={accesos && accesos[3]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </Link>
                    ) : (
                      <a
                        href={
                          !accesos[3]?.tieneEnlace ? "#" : accesos[3]?.enlace
                        }
                        target={accesos[3]?.tieneEnlace ? "_blank" : "_parent"}
                      >
                        <img
                          src={accesos && accesos[3]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </a>
                    )}
                  </div>
                </div>

                <div className={"row m-1"}>
                  {/* IMG5 */}
                  <div className="col-12 col-md-6">
                    {accesos && accesos[4]?.enlaceInterno ? (
                      <Link
                        to={!accesos[4]?.tieneEnlace ? "#" : accesos[4]?.enlace}
                      >
                        <img
                          src={accesos && accesos[4]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </Link>
                    ) : (
                      <a
                        href={
                          !accesos[4]?.tieneEnlace ? "#" : accesos[4]?.enlace
                        }
                        target={accesos[4]?.tieneEnlace ? "_blank" : "_parent"}
                      >
                        <img
                          src={accesos && accesos[4]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </a>
                    )}
                  </div>

                  {/* IMG6 */}
                  <div className="col-12 col-md-6">
                    {accesos && accesos[5]?.enlaceInterno ? (
                      <Link
                        to={!accesos[5]?.tieneEnlace ? "#" : accesos[0]?.enlace}
                      >
                        <img
                          src={accesos && accesos[5]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </Link>
                    ) : (
                      <a
                        href={
                          !accesos[5]?.tieneEnlace ? "#" : accesos[5]?.enlace
                        }
                        target={accesos[5]?.tieneEnlace ? "_blank" : "_parent"}
                      >
                        <img
                          src={accesos && accesos[5]?.imagenAcceso.url}
                          className="img-thumbnail caja-acceso"
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn"
              onClick={() => executeScroll()}
            >
              <img src={Arrow} style={{ width: "30pt" }} alt="arrow" />
            </button>
          </div>
        </div>
      </div>
    </section>    
  ) : (
    <></>
  );
};

export default NuestrosAccesosComponent;
