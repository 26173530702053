import "./style.css";
import React, { useRef, useEffect } from "react";
import { fetch } from "../../../utils/fetch";
import { Modal, Row, Col } from "react-bootstrap";
import Arrow from "../../../assets/img/arrow_down.png";
import LatestYouTubeVideo from "./LatestYouTubeVideo";

const SocialNetworkComponent = () => {
  const [dataSocial, setDataSocial] = React.useState();
  const myRef = useRef(null);

  const executeScroll = () => scrollToRef(myRef);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  const getSocialNetwork = () => {
    fetch(`social-networks`, "GET")
      .then(({ data }) => {
        if (data.length) setDataSocial(data[data.length - 1]);
      })
      .catch((err) => {});
  };

  const getYouTubeVideoId = (url) => {
    const iframeRegex =
      /<iframe[^>]+src="(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
    const urlRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    let matches = url.match(iframeRegex);
    if (!matches) {
      matches = url.match(urlRegex);
    }

    return matches ? `https://www.youtube.com/embed/${matches[1]}` : url;
  };

  React.useEffect(() => {
    getSocialNetwork();
  }, []);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [dataSocial]);

  return (
    <section className="section-social-network" ref={myRef}>
      <div className="container">
        <div className="row pt-5 pb-5">
          <div className="col-lg-12 section-novedades_titulo">
            <div className="d-none d-lg-block">
              <h2 className="text-white">Redes sociales</h2>
            </div>
            <div className="d-block d-lg-none">
              <h2 className="text-white">Redes sociales</h2>
            </div>
          </div>
        </div>

        {dataSocial?.url_video_youtube && (
          <div className="row">
            <div className="col-lg-6 contenedor-youtube">
              <div className="social-container-small">
                <LatestYouTubeVideo
                  dataSocial={dataSocial}
                  getYouTubeVideoId={getYouTubeVideoId}
                />
              </div>
              <div className="social-container-small">
                <iframe
                  id="iframe-social2"
                  src={getYouTubeVideoId(
                    dataSocial?.url_video_youtube_estatico
                  )}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  title="Vídeo para youtube"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6" style={{ alignItems: "center" }}>
              <div className="social-container-small instagram-container">
                {dataSocial?.url_imagen_instagram && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataSocial.url_imagen_instagram,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn"
              onClick={() => executeScroll()}
            >
              <img src={Arrow} style={{ width: "30pt" }} alt="arrow" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialNetworkComponent;
