import React from "react";
import SliderCentrosMedicosComponent from "../slide-centros-medicos";
import "./centros-medicos.css";
import Arrow from "../../../assets/img/arrow_down.png";

const CentrosMedicosComponent = () => {
  return (
    <section className="section-centros_medicos">
      <div className="container">
        <div className="row mt-5">
          <div className="col">
            <div className="d-none d-lg-block">
              <h2 className="section-centros_medicos_titulo">
                Nuestra Red de Centros Médicos
              </h2>
            </div>
            <div className="d-block d-lg-none">
              <h2 className="section-centros_medicos_titulo_movil">
                Nuestra Red de Centros Médicos
              </h2>
            </div>
          </div>
        </div>

        <div className="row pb-5 contenido-centro-medico">
          <div className="col">
            <SliderCentrosMedicosComponent />
          </div>
        </div>
      </div>
    </section>
  );
};
export default CentrosMedicosComponent;
