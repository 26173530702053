import React, { useRef } from "react";
import "./estudia.css";
import { fetch } from "../../../utils/fetch";
import { Link } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../../context/globalContext";
import Arrow from "../../../assets/img/arrow_down.png";

const EstudiaComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const { EstudiosSectionFetchData } = React.useContext(
    GlobalContextMemorySpace
  );

  React.useEffect(() => {
    // getContenido()
    setContenido(EstudiosSectionFetchData && EstudiosSectionFetchData[0]);
  }, [EstudiosSectionFetchData]);

  // const getContenido = async () => {
  //   const { data } = await fetch(`seccion-home-estudias?_locale=es`, 'GET');
  //   console.log("ESTUDIA EN HB", data)
  //   setContenido(data[0])
  // }

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  return (
    <>
      {contenido && (
        <>
          <section className="section-estudia" ref={myRef}>
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-6">
                  <img
                    className="img-fluid b-radius-10 imagen-estudia"
                    src={contenido?.foto[0]?.url}
                    alt=""
                  />
                </div>
                <div className="col-lg-6 p-4">
                  <h2 className="section-estudia_titulo">{contenido.titulo}</h2>
                  <p className="section-estudia_texto">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contenido.descripcion,
                      }}
                    />

                    {contenido.boton.urlInterna ? (
                      <Link
                        to={contenido.boton.url}
                        className="section-estudia_boton_info mt-4"
                      >
                        {contenido.boton.nombre}
                      </Link>
                    ) : (
                      <a
                        href={contenido.boton.url}
                        className="section-estudia_boton_info mt-4"
                        target="_blank"
                      >
                        {contenido.boton.nombre}
                      </a>
                    )}
                  </p>
                  <br />
                </div>
                <div className="row pt-5">
                  <div className="col-lg-12 d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => executeScroll()}
                      style={{ marginLeft: "24px" }}
                    >
                      <img src={Arrow} style={{ width: "30pt" }} alt="arrow" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default EstudiaComponent;
