/* eslint-disable eqeqeq */
import "./slide.css";
import React, { useRef } from "react";
import { Nav } from "react-bootstrap";
import Slider from "react-slick";
import Arrow from "../../../assets/img/arrow_down.png";
import { Link } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../../context/globalContext";

const SliderComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const { SliderSectionFetchData } = React.useContext(GlobalContextMemorySpace);

  React.useEffect(() => {
    setContenido(SliderSectionFetchData || []);
  }, [SliderSectionFetchData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    arrows: false,
    variableWidth: false,
  };

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <section ref={myRef}>
      <div style={{ width: "calc(100vw - 17px)" }}>
        <Slider {...settings}>
          {contenido &&
            contenido.map((data, index) => {
              return (
                <div key={data.id}>
                  {data.tipo == "VIDEO" && (
                    <div className="home-video">
                      {data.enlaceInterno ? (
                        <Link to={data.enlace}>
                          <video
                            className="home-video-imagen"
                            src={data.media.url}
                            autoPlay
                            loop
                            muted
                            playsInline
                          />
                        </Link>
                      ) : (
                        <a href={data.enlace} target="_blank">
                          <video
                            className="home-video-imagen"
                            src={data.media.url}
                            autoPlay
                            loop
                            muted
                            playsInline
                          />
                        </a>
                      )}

                      <div className="home-video-captions">
                        <div className="row">
                          <div className="col">
                            <h1 className="fondo-difuminado">
                              <b>{data.caption.h1}</b>
                            </h1>
                            <h3 className="fondo-difuminado">
                              {data.caption.h3}
                            </h3>
                            <div className="botones-grupo-video">
                              {data.caption.botones.map((btnData, index) => {
                                return (
                                  <Nav.Link href={btnData.url} target="_blank">
                                    <button
                                      key={index}
                                      type="button"
                                      className={`btn home-video-captions__btn${btnData.estilo}`}
                                      // className={`btn home-video-captions__btn${btnData.estilo} me-3`}
                                      // className={`btn btnSlide`}
                                    >
                                      {btnData.nombre}
                                    </button>
                                  </Nav.Link>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-none d-lg-block">
                        <div className="arrowDownButton">
                          <div className="row">
                            <div className="col d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn"
                                onClick={() => executeScroll()}
                              >
                                <img
                                  src={Arrow}
                                  style={{ width: "30pt" }}
                                  alt="arrow"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {data.tipo == "IMAGEN" && (
                    <div className="home-video">
                      {data.enlaceInterno ? (
                        <Link to={data.enlace}>
                          <img
                            src={data.media.url}
                            className="home-video-imagen"
                            alt="imagen"
                          />
                        </Link>
                      ) : (
                        <a href={data.enlace} target="_blank">
                          <img
                            src={data.media.url}
                            className="home-video-imagen"
                            alt="imagen"
                          />
                        </a>
                      )}

                      <div className="d-none d-lg-block">
                        <div className="arrowDownButton">
                          <div className="row">
                            <div className="col d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn"
                                onClick={() => executeScroll()}
                              >
                                <img
                                  src={Arrow}
                                  style={{ width: "30pt" }}
                                  alt="imagen"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </Slider>
      </div>
    </section>
  );
};

export default SliderComponent;
